import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Layout} from "antd/es";
import { Spin } from 'antd';
import jwt_decode from "jwt-decode";
import {Footer} from "../Footer/footer";
import Header from "../Header/header";
import {Sidebar} from "../Sidebar/sidebar";
import {useSelector} from "react-redux";
import {resetCalendarStatus, selectCalendarStatus} from "../../../features/calendar/calendarSlice";
import React, {useEffect} from "react";
import {loginUser, logoutUser, selectLoginStatus, selectUser} from "../../../features/user/userSlice";
import {useAppDispatch} from "../../hooks";
import './protected-router.css'

const ProtectedRouter = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const calendarStatus = useSelector(selectCalendarStatus);
    const loginStatus = useSelector(selectLoginStatus);
    const user = useSelector(selectUser);
    const location = useLocation();

    const protectedRoutes = ['/interactions','/users','/entities','/reports']

    useEffect(()=>{
        if(calendarStatus==='failed') {
            // dispatch(logoutUser())
            dispatch(resetCalendarStatus())
        }

    },[calendarStatus])

    useEffect(() => {
        if(protectedRoutes.some(str => location.pathname.includes(str)) && user?.role==='volunteer') {
            navigate('/dashboard')
        };
    }, [location]);

    useEffect(()=>{
        if(loginStatus==='failed') navigate('/login');
    },[loginStatus])

    useEffect(()=> {
        if(!user) {
            const googleToken = window.localStorage.getItem('googleAuthToken');
            if (googleToken) dispatch(loginUser(googleToken));
            else navigate('/login');
        }
    }, [user])

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sidebar/>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} />
                {loginStatus==='idle'?<Outlet/>:<Spin/>}
                <Footer/>
            </Layout>
        </Layout>
    )
}

export default ProtectedRouter;
